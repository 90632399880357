import React, { ReactNode } from 'react';

interface IMessageBubbleProps {
    role: string;
    content: ReactNode; // changed from 'string' to 'ReactNode'
}

const MessageBubble: React.FC<IMessageBubbleProps> = ({ role, content }) => {
    return (
        <div className={`my-2 max-w-md font-sans font-medium flex items-start ${role === 'user' ? 'self-end justify-end flex-row-reverse' : 'self-start'}`}>
            {role !== 'user' &&
                <img className="h-4 w-4 mr-2 my-1" src="https://cdn.nhb.mx/ziplineMiniLogo.png" alt="Assistant Avatar" />
            }
            <div className={`py-2 px-3 rounded-lg ${role === 'user' ? 'text-slate-800 bg-slate-300' : 'text-slate-700 bg-slate-100'}`}>
                <p>{content}</p>
            </div>
        </div>
    );
};

export default MessageBubble;
