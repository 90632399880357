// Conversation.tsx
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import MessageBubble from './MessageBubble';
import Header from './Header';
import TypingAnimation from './TypingAnimation';
import Completed from './Completed';
import apiService from '../Services/apiService';

interface IMessage {
    role: string;
    content: string;
}

interface IConversation {
    id: string;
    name: string;
    jobTitle: string;
    candidateName: string;
    completed: boolean;
    conversation: IMessage[];
}

const Conversation = () => {
    let { guid } = useParams<{ guid: string }>();
    const [data, setData] = useState<IConversation | null>(null);
    const [inputMessage, setInputMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const inputRef = useRef<HTMLTextAreaElement>(null);
    const bottomRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchConversation = async () => {
            const data = await apiService.getConversation(guid);
            setData(data);
        };

        fetchConversation(); // initial fetch

        const interval = setInterval(fetchConversation, 30000); // fetch every 30 seconds

        return () => clearInterval(interval); // cleanup function to clear the interval when the component unmounts
    }, [guid]);

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [data]);

    const sendMessage = async () => {
        // Optimistically update UI
        setData((prevData) => {
            if (prevData) {
                return {
                    ...prevData,
                    conversation: [...prevData.conversation, { role: 'user', content: inputMessage }],
                }
            }
            return null;
        });
        setInputMessage("");
        setIsLoading(true);
        const newData = await apiService.postMessage(guid, inputMessage);
        setData(newData);
        setIsLoading(false);
    };


    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            sendMessage();
        }
    };

    if (data?.completed) {
        return <Completed candidateName={data.candidateName} />;
    }

    return (
        <div className="px-3 lg:px-5 flex flex-col h-screen max-w-6xl mx-auto">
            <Header candidateName={data?.candidateName || ''} />
            <div className="overflow-auto flex flex-col justify-between pb-3 lg:px-2">
                {data?.conversation.map((message, index) => (
                    <MessageBubble key={index} role={message.role} content={message.content} />
                ))}
                {isLoading && <MessageBubble role="assistant" content={<TypingAnimation />} />}
                <div ref={bottomRef} />
            </div>
            <div className="mt-auto flex items-center pb-2 lg:pb-5 sticky bottom-0 bg-white">
                <textarea
                    autoCapitalize='sentences'
                    className="w-full px-5 py-3 border rounded-l-md resize-none overflow-hidden"
                    rows={Math.min(3, inputMessage.split('\n').length)}
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                    onClick={() => {
                        setTimeout(() => {
                            if (bottomRef.current) {
                                bottomRef.current.scrollIntoView({ behavior: "smooth" });
                            }
                        }, 300);  // delay of 300 milliseconds
                    }}
                />
                <button
                    className="px-5 py-4 bg-blue-500 text-white font-medium rounded-r-md flex items-center justify-center"
                    onClick={sendMessage}
                >
                    <PaperAirplaneIcon className="h-5 w-5" />
                </button>
            </div>
        </div>
    );
};

export default Conversation;
