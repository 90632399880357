// Header.tsx
import React from 'react';

interface IHeaderProps {
    candidateName: string;
}

const Header: React.FC<IHeaderProps> = ({ candidateName }) => {
    return (
        <div className="border-b border-slate-100 py-4 mb-5">
            <div className="flex justify-between items-center mb-4">
                <img className="h-8" src="https://cdn.nhb.mx/uniting-logo.jpeg" alt="Uniting" />
                {/* <img className="h-8" src="https://cdn.nhb.mx/irtLogo.png" alt="IRT" /> */}
                <a href="https://zipline.io" className="text-blue-500">Get help</a>
            </div>
            <h2 className="text-center text-lg font-semibold text-slate-800">Reference Check for {candidateName}</h2>
        </div>
    );
};

export default Header;
