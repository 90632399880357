// apiService.ts
const BASE_URL = 'https://zippyrefcheck.azurewebsites.net/api';

export default class apiService {
    static async getConversation(guid: string | undefined) {
        const response = await fetch(`${BASE_URL}/referee/${guid}`);
        const data = await response.json();
        return data;
    };

    static async postMessage(guid: string | undefined, message: string) {
        const response = await fetch(`${BASE_URL}/referee/${guid}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ answer: message }),
        });
        const data = await response.json();
        return data;
    }
}
